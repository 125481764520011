@import "./src/styles/helpers/_index.scss";
.ArrowButton {
  position: absolute;
  bottom: 40px;

  &Icon {
    display: inline-block;
    width: 27px;
    height: 27px;
    border-bottom: 2px solid $main-grey;
    border-right: 2px solid $main-grey;
    opacity: .8;
    transform: rotate(45deg);
    animation: arrow 2s infinite;

    &:hover {
      opacity: 1;
    }
  }
}

@keyframes arrow {
  0% {
    transform: rotate(45deg) translate(-5px, -5px);
  }
  50% {
    transform: rotate(45deg) translate(0, 0);
  }
  100% {
    transform: rotate(45deg) translate(-5px, -5px);
  }
}