@import "./src/styles/helpers/_index.scss";
.Countdown {
  position: relative;
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  margin: 10px 10px 10px 15px;

  vertical-align: middle;
  text-align: center;

  &Value {
    display: inline-block;

    line-height: 40px;
    color: $text-white;
  }

  &Svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;

    transform: rotateY(-180deg) rotate(-90deg);

    circle {
      stroke-dasharray: 113px;
      stroke-dashoffset: 0px;
      stroke-linecap: round;
      stroke-width: 2px;
      stroke: $basic-white;
      fill: none;

      animation: countdown;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes countdown {
  0% { stroke-dashoffset: 0px; }
  to { stroke-dashoffset: 113px; }
}
