@import "./src/styles/helpers/_index.scss";
.Layout {
  font-family: Roboto, Arial, sans-serif;
  background-color: $main-light-grey;
}

.Promo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 50px;
  height: calc(100vh - 80px);

  text-align: center;
  background: linear-gradient(to bottom, rgba(32,44,62,0.70), rgba(32,44,62,0.90)),
    url('assets/promo.jpg') 50% / cover no-repeat;
  color: $basic-white;

  .ButtonWrapper {
    width: 255px;
  }
}

.Title {
  margin: 0;
  font-weight: 600;
  font-size: 72px;

  @include breakpoint-mobile {
    font-size: 50px;
  }
}

.Subtitle {
  margin: 50px 0 80px;
  font-weight: 400;
  font-size: 22px;

  @include breakpoint-mobile {
    font-size: 18px;
  }
}

.Description {
  display: flex;
  align-items: center;
  background-color: $basic-dark;
  padding: 70px 100px;

  @include breakpoint-tablet-portrait {
    flex-direction: column;
    padding: 50px 70px;
  }

  @include breakpoint-mobile-portrait {
    padding: 50px 40px;
  }

  &Images {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 55%;

    @include breakpoint-tablet-portrait {
      width: 100%;
    }
  }

  &SingleImage {
    width: 100%;
    margin: 0 auto;
    max-width: 1000px;

    @include breakpoint-tablet-portrait {
      max-width: 500px;
    }
  }

  &Image {
    width: 50%;
    padding: 5px;
  }

  &Text {
    flex-basis: 45%;
    margin-left: 50px;
    font-size: 20px;
    line-height: 1.55;
    color: $basic-white;

    @include breakpoint-tablet {
      font-size: 18px;
    }

    @include breakpoint-tablet-portrait {
      margin-left: 0;
    }
  }

  &Title {
    font-weight: 600;
    font-size: 42px;

    @include breakpoint-tablet {
      font-size: 32px;
    }

    @include breakpoint-mobile {
      font-size: 29px;
    }
  }

  &Subtitle {
    font-size: 35px;

    @include breakpoint-mobile {
      font-size: 26px;
    }

    @include breakpoint-mobile-portrait {
      font-size: 20px;
    }
  }

  &Wide {
    @include breakpoint-tablet {
      flex-direction: column;
      justify-content: center;
    }
  }

  &Wide &Images {
    @include breakpoint-tablet {
      width: 100%;
    }
  }

  &Wide &Text {
    @include breakpoint-tablet {
      margin-top: 40px;
      text-align: center;
    }
  }


  .ButtonWrapper {
    margin: 100px auto 0;
    max-width: 290px;
  
    @include breakpoint-tablet {
      margin-top: 50px;
    }
  }
}

.Button {
  display: inline-block;
  width: 100%;
  padding: 18px 25px;
  border: 1px solid transparent;
  outline: none;
  border-radius: 30px;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4;
  text-decoration: none;
  cursor: pointer;
  background-color: $main-success;
  color: $text-white;

  &Dark {
    background-color: $basic-dark;
  }

  &WithBorder {
    &:hover {
      border-color: $basic-white;
    }
  }
}

.Features {
  padding: 100px 20px;

  @include breakpoint-mobile {
    padding-top: 30px;
  }

  &List {
    display: flex;
    justify-content: center;

    @include breakpoint(1000px) {
      flex-direction: column;
      justify-content: stretch;
    }
  }

  &Item {
    width: 33.3%;
    max-width: 460px;
    min-width: 365px;
    padding: 200px 30px 0;
    font-size: 16px;
    line-height: 1.55;
    text-align: center;
    color: $main-dark-grey;

    @include breakpoint-tablet {
      min-width: 315px;
      padding: 200px 15px 0;
      font-size: 14px;
    }

    @include breakpoint(1000px) {
      width: 100%;
      margin-top: 50px;
      max-width: unset;

      &:first-child {
        margin-top: 0;
      }
    }

    @include breakpoint-mobile {
      margin-top: 20px;
    }

    @include breakpoint-mobile-portrait {
      min-width: unset;
    }
  }

  .ButtonWrapper {
    margin: 100px auto 0;
    max-width: 320px;
  
    @include breakpoint-tablet {
      margin-top: 50px;
    }
  }
}

.Feature {
  &Title {
    position: relative;
    margin-bottom: 25px;
    margin-top: 12px;
    font-weight: 600;
    font-size: 30px;
    line-height: 1.17;
    word-wrap: break-word;
    color: $main-success;

    @include breakpoint-tablet {
      font-size: 26px;
    }

    @include breakpoint-mobile-portrait {
      font-size: 20px;
    }

    &::before {
      content: "";
      position: absolute;
      top: -200px;
      left: calc(50% - 100px);
      width: 200px;
      height: 200px;
    }

    &Graph {
      &::before {
        background: url('assets/icon-graph.jpg');
        background-size: contain;
      }
    }

    &Calc {
      &::before {
        background: url('assets/icon-calc.jpg');
        background-size: contain;
      }
    }

    &Lock {
      &::before {
        background: url('assets/icon-lock.jpg');
        background-size: contain;
      }
    }
  }
}

.Rates {
  padding: 100px 250px;
  background-color: $main-success;
  color: $basic-white;

  @include breakpoint-tablet {
    padding: 100px 150px;
  }

  @include breakpoint-tablet-portrait {
    padding: 100px;
  }

  @include breakpoint-mobile {
    padding: 50px;
  }

  &Title {
    font-weight: 700;
    font-size: 52px;
    line-height: 1.2;

    @include breakpoint-tablet {
      font-size: 48px;
    }

    @include breakpoint-mobile {
      font-size: 30px;
    }
  }

  &Subtitle {
    font-size: 37px;
    margin: 50px 0 70px;

    @include breakpoint-tablet {
      font-size: 26px;
      margin-top: 40px;
    }

    @include breakpoint-mobile {
      font-size: 20px;
      margin-bottom: 50px;
    }
  }

  .ButtonWrapper {
    max-width: 300px;
  }
}

.Form {
  padding: 105px 80px;
  background-color: $basic-dark;

  @include breakpoint-tablet-portrait {
    padding: 35px;
  }

  @include breakpoint-mobile {
    padding-top: 60px;
  }
}

.FormWrapper {
  max-width: 960px;
  margin: 0 auto;

  @include breakpoint-tablet-portrait {
    max-width: 640px;
  }
}
