@import "./src/styles/helpers/_index.scss";
.AccessForm {
  text-align: center;
  font-family: Roboto, Arial, sans-serif;
}

.Header {
  margin-bottom: 76px;

  @include breakpoint-tablet-portrait {
    margin-bottom: 35px;
  }
}

.Title {
  margin-bottom: 24px;

  font-size: 50px;
  font-weight: 600;
  line-height: 1.23;
  color: $basic-white;

  @include breakpoint-tablet {
    font-size: 48px;
  }

  @include breakpoint-mobile {
    font-size: 30px;
  }
}

.SubTitle {
  max-width: 600px;
  margin: 0 auto;

  font-size: 16px;
  line-height: 1.4;
  color: #8a8d8c;
}

.Form {
  position: relative;
  display: flex;
  width: 100%;

  @include breakpoint-tablet-portrait {
    flex-direction: column;
  }
}

.InputWrapper {
  flex: 270px 1 1;
  min-width: 190px;
  margin-right: 20px;

  &Phone {
    flex: 280px 2 1;
  }

  @include breakpoint-tablet-portrait {
    flex: 0 0 auto;
    width: 100%;
    margin: 0 0 16px 0;
  }
}

.ButtonWrapper {
  flex: auto 1 1;

  @include breakpoint-tablet-portrait {
    width: 100%;
  }
}

.MessageBox {
  width: 100%;
  text-align: center;

  overflow: hidden;
  opacity: 0;
  height: 0;
  padding: 0;
  transform: rotateX(90deg);
  transform-origin: 50% 0;
  transition: all 0.2s;

  &Error {
    list-style: none;
    background: #f95d51;

    &.MessageBoxVisible {
      padding: 10px;
    }
  }

  &Success {
    background: #62c584;

    &.MessageBoxVisible {
      padding: 20px;
    }
  }

  &Visible {
    margin: 20px 0;
    height: auto;
    opacity: 1;
    transform: rotateX(0deg);
    transition: all 0.4s cubic-bezier(.25,.75,.5,1.25), padding 0;
  }
}

.Message {
  margin: 10px 0;

  font-size: 20px;
  font-weight: 400;
  line-height: 1.55;
  color: $basic-white;

  @include breakpoint-mobile {
    font-size: 16px;
  }
}

.Agreement {
  margin-top: 30px;

  font-size: 15px;
  line-height: 1.55;
  color: #939393;

  a {
    color: white;
    text-decoration: none;
  }
}