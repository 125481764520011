@import "./src/styles/helpers/_index.scss";
$size: 120;               // px
$smallSize: 14;           // px
$textFontSize: 16;        // px
$time: 2;                 // seconds
$count: 12;

@mixin fill-animation($circle-size) {
  $circumference: 3.141592 * $circle-size;

  stroke-dasharray: 0.01 * $circumference, $circumference;
  animation: rotateDash#{$circle-size} $time + s ease-in-out infinite;

  @keyframes rotateDash#{$circle-size} {
    0% {
      stroke-dasharray: 0.01 * $circumference, $circumference;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 0.7 * $circumference, $circumference;
      stroke-dashoffset: -0.25 * $circumference;
    }
    100% {
      stroke-dasharray: 0.7 * $circumference, $circumference;
      stroke-dashoffset: -0.99 * $circumference;
    }
  }
}

.Loader {
  visibility: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  opacity: 0;

  transition: all 0.2s ease;

  z-index: 1000;

  &Absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &WithBackground {
    background-color: rgba(0, 0, 0, 0.15);
  }

  &Active {
    visibility: visible;

    opacity: 1;
  }

  &Content {
    position: relative;
    width: $size + px;
    height: $size + px;

    margin: 0 auto;

    text-align: center;
  }

  &Animation {
    position: absolute;
    left: 50%;
    top: 50%;

    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;

    overflow: visible;
    transition: all .2s ease;
    transform: translateX(-50%) translateY(-50%);
  }

  &Ring &Animation {
    animation: rotatePreloader $time + s infinite linear;

    @keyframes rotatePreloader {
      0% {
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
      }
      
      100% {
        transform: translateX(-50%) translateY(-50%) rotate(360deg)
      }
    }

    .LoaderAnimationItem {
      @include fill-animation($size);
      stroke-dashoffset: 0;
      stroke: #c9f0ff;
      stroke-width: 6px;
    }
  }

  &Bubbles &Animation {
    list-style: none;
    animation: rotatePreloader3d $time + s infinite ease-in;

    @keyframes rotatePreloader3d {
      0% {
        transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
      }
      100% {
        transform: translateX(-50%) translateY(-50%) rotateZ(-360deg);
      }
    }

    .LoaderAnimationItem {
      position: absolute;

      width: 100%;
      height: 100%;

      opacity: 0;

      &:before {
        content: '';

        position: absolute;
        left: 50%;
        top: 0;

        width: 12%;
        height: 12%;

        background-color: #f48e28;

        transform: translateX(-50%);

        border-radius: 50%;
      }

      @for $i from 1 through $count {
        &:nth-child(#{$i}) {
          transform: rotateZ(((360 / $count) * ($i - 1)) + deg);
          animation: rotateCircle + $i $time + s infinite linear;
          z-index: $count - $i;
        }

        @keyframes rotateCircle#{$i} {
          #{percentage(((50 / $count) * ($i - 1)) / 100)} {
            opacity: 0;
          }
          #{percentage((((50 / $count) + 0.0001) * ($i - 1)) / 100)} {
            opacity: 1;
            transform: rotateZ((0 - ((360 / $count) * ($i - 2))) + deg);
          }
          #{percentage((((50 / $count) * ($i - 0)) + 2) / 100)} {
            transform: rotateZ((0 - ((360 / $count) * ($i - 1))) + deg);
          }
          #{percentage(((50 + ((50 / $count) * ($i - 0))) + 2) / 100)} {
            transform: rotateZ((0 - ((360 / $count) * ($i - 1))) + deg);
          }
          100% {
            transform: rotateZ((0 - ((360 / $count) * ($count - 1))) + deg);
            opacity: 1;
          }
        }
      }
    }
  }

  &Text {
    margin: 0;
    padding: #{($size - $textFontSize) / 2}px 0;

    font-size: $textFontSize + px;
    line-height: 1;
    font-weight: 400;
    color: $text-white;
    text-shadow: 1px 1px 2px #003b49;
  }

  &Description {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;

    margin: #{$size / 2 + 20}px 0 0;

    font-size: 16px;
    font-weight: 400;
    color: $text-white;
    text-align: center;
    text-shadow: 1px 1px 2px #001f2b;
  }

  &Small &Content {
    width: $smallSize + px;
    height: $smallSize + px;
  }

  &Small#{&}Ring &AnimationItem {
    stroke-width: 3px;
    @include fill-animation($smallSize);
  }
}