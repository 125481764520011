@import "./src/styles/helpers/_index.scss";
.Input {
  display: block;
  width: 100%;
  line-height: 14px;
  padding: 5px 0px;
  border: none;
  border-bottom: 1px solid $basic-grey;
  outline: none;
  box-sizing: border-box;
  overflow: hidden;

  font-family: Rubik, sans-serif;
  color: $basic-dark;

  &:hover {
    border-bottom-color: #b3b3b3;
  }

  &.WithError {
    border-bottom-color: $purple;
  }

  &Disabled {
    background-color: rgb(235, 235, 228);
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &Round {
    padding: 16px 20px;
    background: #f0f0f0;
    border: 2px solid transparent;
    border-radius: 30px;

    font: normal 16px/1.33 Roboto, Arial, sans-serif;
    color: $text-black;

    &.WithError {
      border: 2px solid $purple;
    }
  }
}

.InputPhone {
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible;

  &Field {
    width: 100%;
    padding: 0;
    border: none;
    background: transparent;

    font: normal 16px/1.33 Roboto, Arial, sans-serif;
    color: $text-black;
    outline: none;
  }
}

.FlagButton {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 14px 0 0;
  margin-right: 10px;

  border: none;
  background: none;

  outline: none;
  cursor: pointer;
}