@import "./src/styles/helpers/_index.scss";
.Layout {
  font-family: Roboto, Arial, sans-serif;
  background-color: $basic-dark;
}

.Promo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  height: 450px;
  position: relative;

  text-align: center;
  background: linear-gradient(to bottom, rgba(32,44,62,0.80), rgba(32,44,62,0.80)),
    url('assets/silver-coin-stack-on.jpg') 50% / cover no-repeat;
  color: $basic-white;

  @include breakpoint-mobile {
    height: auto;
    padding-bottom: 70px;
  }
}

.Title {
  margin: 85px 0;
  font-weight: 600;
  font-size: 72px;

  @include breakpoint-tablet-portrait {
    font-size: 52px;
  }

  @include breakpoint-mobile {
    font-size: 36px;
  }

  @include breakpoint-mobile-portrait {
    font-size: 30px;
  }

  span {
    text-transform: uppercase;
  }
}

.Main {
  padding: 80px 50px;
  max-width: 960px;
  margin: 0 auto;

  @include breakpoint-mobile-portrait {
    padding: 60px 30px;
  }

  &Title {
    font-size: 30px;
    font-weight: 600;
    color: #36a8ac;

    @include breakpoint-tablet-portrait {
      font-size: 25px;
    }
  }
}

.Rates {
  width: 100%;
  margin: 140px 0 0;
  padding: 0;
  list-style: none;

  @include breakpoint-tablet-portrait {
    margin-top: 70px;
  }

  &Item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    @include breakpoint-tablet-portrait {
      flex-wrap: wrap;
    }
  }

  &Text {
    font-size: 18px;
    line-height: 1.55;
    font-weight: 400;
    color: #8a8d8c;

    &Highlighted {
      font-size: 20px;
      font-weight: 600;
      color: #36a8ac;

      @include breakpoint-tablet-portrait {
        font-size: 18px;
      }
    }

    &ForName {
      flex: 0 0 210px;
      margin-right: 70px;

      @include breakpoint-tablet-portrait {
        flex: 1 1 100%;
        margin: 0 0 20px 0;
      }
    }

    &ForInfo {
      flex: 1 1 100%;
      
      @include breakpoint-tablet-portrait {
        flex: 1 1 50%;
      }
    }

    &ForPrice {
      min-width: 150px;
      max-width: 250px;
      margin-left: 40px;

      text-align: right;

      @include breakpoint-tablet-portrait {
        margin-left: 30px;
        max-width: 200px;
      }

      @include breakpoint-mobile {
        max-width: unset;
        flex: 1 0 100%;
        margin: 10px 0 10px;
        text-align: left;
      }
    }
  }
}
