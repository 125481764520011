@import "./src/styles/helpers/_index.scss";
.Root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  background: #f0f3f8;
}

.Scrollbar {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.Main {
  flex: 1 1 1rem;
}
