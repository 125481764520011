@import "./src/styles/helpers/_index.scss";
.ScrollTopButton {
  display: none;
  position: fixed;
  bottom: 50px;
  right: 70px;

  font-size: 14px;
  line-height: 2.3;
  font-weight: 700;

  border-radius: 15px;
  background: rgba(32,44,62,0.60);
  z-index: 1000;

  @include breakpoint-tablet-portrait {
    font-size: 12px;
    bottom: 40px;
    right: 50px;
  }

  @include breakpoint-mobile-portrait {
    font-size: 10px;
    bottom: 20px;
    right: 20px;
  }

  &Visible {
    display: block;
  }

  &Text {
    text-decoration: none;
    color: $basic-white;
    padding: 5px 8px 6px 10px;
  }

  &:hover {
    background-color: $main-success;
  }

  &Arrow {
    font-size: 1.72em;
    line-height: 0.8;
  }
}