:global {
  [class^='icon-']:before,
  [class*=' icon-']:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'clientspace' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-bold:before {
    content: "\e800";
  }
  .icon-arrow-left:before {
    content: "\e801";
  }
  .icon-arrow-right:before {
    content: "\e802";
  }
  .icon-arrow-world:before {
    content: "\e803";
  }
  .icon-globe:before {
    content: "\e803";
  }
  .icon-arrow-down:before {
    content: "\e804";
  }
  .icon-tv2:before {
    content: "\e805";
  }
  .icon-user:before {
    content: "\e806";
  }
  .icon-lock:before {
    content: "\e807";
  }
  .icon-export:before {
    content: "\e808";
  }
  .icon-load:before {
    content: "\e809";
  }
  .icon-check:before {
    content: "\e80a";
  }
  .icon-edit:before {
    content: "\e80b";
  }
  .icon-delete:before {
    content: "\e80c";
  }
  .icon-radio:before {
    content: "\e80d";
  }
  .icon-cabinet:before {
    content: "\f187";
  }
  .icon-paper:before {
    content: "\f1ea";
  }
  .icon-tv:before {
    content: "\f26c";
  }
  .icon-search:before {
    content: "\e80e";
  }
  .icon-save:before {
    content: "\e962";
  }
  .icon-reset:before {
    content: "\ea0d";
  }
}