@import "./src/styles/helpers/_index.scss";
.Layout {
  font-family: Roboto, Arial, sans-serif;
  background-color: $main-light-grey;

  main {
    background-color: #003b49;
  }
}

.Promo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  height: 450px;

  text-align: center;
  background: linear-gradient(to bottom, rgba(0,59,73,0.70), rgba(0,59,73,0.90)),
    url('assets/promo-analytics.jpg') 50% / cover no-repeat;
  color: $basic-white;
}

.Title {
  margin: 0;
  font-weight: 600;
  font-size: 72px;

  @include breakpoint-mobile {
    font-size: 36px;
  }
}

.Subtitle {
  margin: 50px 0 80px;
  font-weight: 400;
  font-size: 22px;

  @include breakpoint-mobile {
    font-size: 18px;
  }
}

.BiReport {
  width: 95%;
  margin: auto;
  position: relative;
  overflow: hidden;
  padding-top: 57%;

  @include breakpoint-tablet-portrait {
    padding-top: 61%;
  }

  @include breakpoint-mobile {
    padding-top: 68%;
  }
}

.Iframe {
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
