.react-tel-input {
  .arrow {
    position: absolute;
    right: 0;
    top: 50%;

    border-style: solid;
    border-width: 5px 4px 0;
    border-color: #9a9a9a transparent transparent;

    transform: translateY(-50%);
  }

  .country-list {
    position: absolute;
    height: auto;
    top: 100%;
    left: 0;
    max-height: 200px;
    margin-top: 5px;
    padding: 5px 0;

    border: 1px solid #eee;
    border-radius: 7px;

    @media (max-width: 400px) {
      width: 100%!important;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 15px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      background: #c2c9d2;
    }
  }

  .country {
    display: flex;
    align-items: center;
    padding: 8px 10px;

    font-size: 14px;
    line-height: 1.33;

    cursor: pointer;

    &:hover {
      background-color: #eee;
    }

    .flag {
      margin-left: 10px;
    }
  }

  .country-name {
    flex: auto 1 1;
    text-align: left;
  }

  .dial-code {
    order: 2;
    margin-left: 10px;
  }

  .flag {
    order: 3;
    width: 18px;
    height: 13px;
    background-position: -1000px;

    background-repeat: no-repeat;
    background-color: #eee;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  .flag.ad {
    background-position: -5px -5px;
  }

  .flag.ae {
    background-position: -33px -5px;
  }

  .flag.af {
    background-position: -61px -5px;
  }

  .flag.ag {
    background-position: -89px -5px;
  }

  .flag.al {
    background-position: -117px -5px;
  }

  .flag.am {
    background-position: -145px -5px;
  }

  .flag.ao {
    background-position: -173px -5px;
  }

  .flag.ar {
    background-position: -201px -5px;
  }

  .flag.at {
    background-position: -229px -5px;
  }

  .flag.au {
    background-position: -257px -5px;
  }

  .flag.az {
    background-position: -285px -5px;
  }

  .flag.ba {
    background-position: -313px -5px;
  }

  .flag.bb {
    background-position: -5px -28px;
  }

  .flag.bd {
    background-position: -33px -28px;
  }

  .flag.be {
    background-position: -61px -28px;
  }

  .flag.bf {
    background-position: -89px -28px;
  }

  .flag.bg {
    background-position: -117px -28px;
  }

  .flag.bh {
    background-position: -145px -28px;
  }

  .flag.bi {
    background-position: -173px -28px;
  }

  .flag.bj {
    background-position: -201px -28px;
  }

  .flag.bm {
    background-position: -229px -28px;
  }

  .flag.bn {
    background-position: -257px -28px;
  }

  .flag.bo {
    background-position: -285px -28px;
  }

  .flag.br {
    background-position: -313px -28px;
  }

  .flag.bs {
    background-position: -5px -51px;
  }

  .flag.bt {
    background-position: -33px -51px;
  }

  .flag.bw {
    background-position: -61px -51px;
  }

  .flag.by {
    background-position: -89px -51px;
  }

  .flag.bz {
    background-position: -117px -51px;
  }

  .flag.ca {
    background-position: -145px -51px;
  }

  .flag.cd {
    background-position: -173px -51px;
  }

  .flag.cf {
    background-position: -201px -51px;
  }

  .flag.cg {
    background-position: -229px -51px;
  }

  .flag.ch {
    background-position: -257px -51px;
  }

  .flag.ci {
    background-position: -285px -51px;
  }

  .flag.ck {
    background-position: -313px -51px;
  }

  .flag.cl {
    background-position: -5px -74px;
  }

  .flag.cm {
    background-position: -33px -74px;
  }

  .flag.cn {
    background-position: -61px -74px;
  }

  .flag.co {
    background-position: -89px -74px;
  }

  .flag.cr {
    background-position: -117px -74px;
  }

  .flag.cu {
    background-position: -145px -74px;
  }

  .flag.cv {
    background-position: -173px -74px;
  }

  .flag.cy {
    background-position: -201px -74px;
  }

  .flag.cz {
    background-position: -229px -74px;
  }

  .flag.de {
    background-position: -257px -74px;
  }

  .flag.dj {
    background-position: -285px -74px;
  }

  .flag.dk {
    background-position: -313px -74px;
  }

  .flag.dm {
    background-position: -5px -97px;
  }

  .flag.do {
    background-position: -33px -97px;
  }

  .flag.dz {
    background-position: -61px -97px;
  }

  .flag.ec {
    background-position: -89px -97px;
  }

  .flag.ee {
    background-position: -117px -97px;
  }

  .flag.eg {
    background-position: -145px -97px;
  }

  .flag.eh {
    background-position: -173px -97px;
  }

  .flag.er {
    background-position: -201px -97px;
  }

  .flag.es {
    background-position: -229px -97px;
  }

  .flag.et {
    background-position: -257px -97px;
  }

  .flag.fi {
    background-position: -285px -97px;
  }

  .flag.fj {
    background-position: -313px -97px;
  }

  .flag.fm {
    background-position: -5px -120px;
  }

  .flag.fr {
    background-position: -33px -120px;
  }

  .flag.ga {
    background-position: -61px -120px;
  }

  .flag.gb {
    background-position: -89px -120px;
  }

  .flag.gd {
    background-position: -117px -120px;
  }

  .flag.ge {
    background-position: -145px -120px;
  }

  .flag.gh {
    background-position: -173px -120px;
  }

  .flag.gm {
    background-position: -201px -120px;
  }

  .flag.gn {
    background-position: -229px -120px;
  }

  .flag.gq {
    background-position: -257px -120px;
  }

  .flag.gr {
    background-position: -285px -120px;
  }

  .flag.gt {
    background-position: -313px -120px;
  }

  .flag.gw {
    background-position: -5px -143px;
  }

  .flag.gy {
    background-position: -33px -143px;
  }

  .flag.hk {
    background-position: -61px -143px;
  }

  .flag.hn {
    background-position: -89px -143px;
  }

  .flag.hr {
    background-position: -117px -143px;
  }

  .flag.ht {
    background-position: -145px -143px;
  }

  .flag.hu {
    background-position: -173px -143px;
  }

  .flag.id {
    background-position: -201px -143px;
  }

  .flag.ie {
    background-position: -229px -143px;
  }

  .flag.il {
    background-position: -257px -143px;
  }

  .flag.in {
    background-position: -285px -143px;
  }

  .flag.iq {
    background-position: -313px -143px;
  }

  .flag.ir {
    background-position: -5px -166px;
  }

  .flag.is {
    background-position: -33px -166px;
  }

  .flag.it {
    background-position: -61px -166px;
  }

  .flag.jm {
    background-position: -89px -166px;
  }

  .flag.jo {
    background-position: -117px -166px;
  }

  .flag.jp {
    background-position: -145px -166px;
  }

  .flag.ke {
    background-position: -173px -166px;
  }

  .flag.kg {
    background-position: -201px -166px;
  }

  .flag.kh {
    background-position: -229px -166px;
  }

  .flag.ki {
    background-position: -257px -166px;
  }

  .flag.km {
    background-position: -285px -166px;
  }

  .flag.kn {
    background-position: -313px -166px;
  }

  .flag.kp {
    background-position: -5px -189px;
  }

  .flag.kr {
    background-position: -33px -189px;
  }

  .flag.ks {
    background-position: -61px -189px;
  }

  .flag.kw {
    background-position: -89px -189px;
  }

  .flag.kz {
    background-position: -117px -189px;
  }

  .flag.la {
    background-position: -145px -189px;
  }

  .flag.lb {
    background-position: -173px -189px;
  }

  .flag.lc {
    background-position: -201px -189px;
  }

  .flag.li {
    background-position: -229px -189px;
  }

  .flag.lk {
    background-position: -257px -189px;
  }

  .flag.lr {
    background-position: -285px -189px;
  }

  .flag.ls {
    background-position: -313px -189px;
  }

  .flag.lt {
    background-position: -5px -212px;
  }

  .flag.lu {
    background-position: -33px -212px;
  }

  .flag.lv {
    background-position: -61px -212px;
  }

  .flag.ly {
    background-position: -89px -212px;
  }

  .flag.ma {
    background-position: -117px -212px;
  }

  .flag.mc {
    background-position: -145px -212px;
  }

  .flag.md {
    background-position: -173px -212px;
  }

  .flag.me {
    background-position: -201px -212px;
  }

  .flag.mg {
    background-position: -229px -212px;
  }

  .flag.mh {
    background-position: -257px -212px;
  }

  .flag.mk {
    background-position: -285px -212px;
  }

  .flag.ml {
    background-position: -313px -212px;
  }

  .flag.mm {
    background-position: -5px -235px;
  }

  .flag.mn {
    background-position: -33px -235px;
  }

  .flag.mr {
    background-position: -61px -235px;
  }

  .flag.mt {
    background-position: -89px -235px;
  }

  .flag.mu {
    background-position: -117px -235px;
  }

  .flag.mv {
    background-position: -145px -235px;
  }

  .flag.mw {
    background-position: -173px -235px;
  }

  .flag.mx {
    background-position: -201px -235px;
  }

  .flag.my {
    background-position: -229px -235px;
  }

  .flag.mz {
    background-position: -257px -235px;
  }

  .flag.na {
    background-position: -285px -235px;
  }

  .flag.ne {
    background-position: -313px -235px;
  }

  .flag.ng {
    background-position: -5px -258px;
  }

  .flag.ni {
    background-position: -33px -258px;
  }

  .flag.nl {
    background-position: -61px -258px;
  }

  .flag.no {
    background-position: -89px -258px;
  }

  .flag.np {
    width: 16px;
    height: 13px;
    background-position: -117px -258px;
  }

  .flag.nr {
    background-position: -143px -258px;
  }

  .flag.nu {
    background-position: -171px -258px;
  }

  .flag.nz {
    background-position: -199px -258px;
  }

  .flag.om {
    background-position: -227px -258px;
  }

  .flag.pa {
    background-position: -255px -258px;
  }

  .flag.pe {
    background-position: -283px -258px;
  }

  .flag.pg {
    background-position: -311px -258px;
  }

  .flag.ph {
    background-position: -5px -281px;
  }

  .flag.pk {
    background-position: -33px -281px;
  }

  .flag.pl {
    background-position: -61px -281px;
  }

  .flag.pt {
    background-position: -89px -281px;
  }

  .flag.pw {
    background-position: -117px -281px;
  }

  .flag.py {
    background-position: -145px -281px;
  }

  .flag.qa {
    background-position: -173px -281px;
  }

  .flag.ro {
    background-position: -201px -281px;
  }

  .flag.rs {
    background-position: -229px -281px;
  }

  .flag.ru {
    background-position: -257px -281px;
  }

  .flag.rw {
    background-position: -285px -281px;
  }

  .flag.sa {
    background-position: -313px -281px;
  }

  .flag.sb {
    background-position: -5px -304px;
  }

  .flag.sc {
    background-position: -33px -304px;
  }

  .flag.sd {
    background-position: -61px -304px;
  }

  .flag.se {
    background-position: -89px -304px;
  }

  .flag.sg {
    background-position: -117px -304px;
  }

  .flag.si {
    background-position: -145px -304px;
  }

  .flag.sk {
    background-position: -173px -304px;
  }

  .flag.sl {
    background-position: -201px -304px;
  }

  .flag.sm {
    background-position: -229px -304px;
  }

  .flag.sn {
    background-position: -257px -304px;
  }

  .flag.so {
    background-position: -285px -304px;
  }

  .flag.sr {
    background-position: -313px -304px;
  }

  .flag.ss {
    background-position: -5px -327px;
  }

  .flag.st {
    background-position: -33px -327px;
  }

  .flag.sv {
    background-position: -61px -327px;
  }

  .flag.sy {
    background-position: -89px -327px;
  }

  .flag.sz {
    background-position: -117px -327px;
  }

  .flag.td {
    background-position: -145px -327px;
  }

  .flag.tg {
    background-position: -173px -327px;
  }

  .flag.th {
    background-position: -201px -327px;
  }

  .flag.tj {
    background-position: -229px -327px;
  }

  .flag.tl {
    background-position: -257px -327px;
  }

  .flag.tm {
    background-position: -285px -327px;
  }

  .flag.tn {
    background-position: -313px -327px;
  }

  .flag.to {
    background-position: -341px -5px;
  }

  .flag.tr {
    background-position: -341px -28px;
  }

  .flag.tt {
    background-position: -341px -51px;
  }

  .flag.tv {
    background-position: -341px -74px;
  }

  .flag.tw {
    background-position: -341px -97px;
  }

  .flag.tz {
    background-position: -341px -120px;
  }

  .flag.ua {
    background-position: -341px -143px;
  }

  .flag.ug {
    background-position: -341px -166px;
  }

  .flag.us {
    background-position: -341px -189px;
  }

  .flag.uy {
    background-position: -341px -212px;
  }

  .flag.uz {
    background-position: -341px -235px;
  }

  .flag.va {
    background-position: -339px -258px;
  }

  .flag.vc {
    background-position: -341px -281px;
  }

  .flag.ve {
    background-position: -341px -304px;
  }

  .flag.vn {
    background-position: -341px -327px;
  }

  .flag.vu {
    background-position: -5px -350px;
  }

  .flag.ws {
    background-position: -33px -350px;
  }

  .flag.ye {
    background-position: -61px -350px;
  }

  .flag.za {
    background-position: -89px -350px;
  }

  .flag.zm {
    background-position: -117px -350px;
  }

  .flag.zw {
    background-position: -145px -350px;
  }
}
