// Basic Colors
$basic-dark: #202c3e; // Primary color
$basic-white: white; // Secondary color
$basic-light-blue: #f0f3f8; // Background color
$basic-dark-blue: #35445a; // Buttons color
$basic-light-grey: #eee;
$basic-grey: #e5e5e5;

$red: #ED5565;
$purple: #d95d5b;

// Various colors
$text-black: black;
$text-grey: #8d8d8d;
$text-white: white;

// Main page colors
$main-success: #35a8ab;
$main-light-grey: #ededed;
$main-dark-grey: #8a8d8c;
$main-grey: #dbdbdb;
$main-lazur: #39af9e;
