@import "./src/styles/helpers/_index.scss";
.NotificationWrapper {
  position: fixed;
  bottom: 30px;
  right: calc(50% - 250px);
  width: 500px;
  z-index: 3000;
}

.Notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  padding: 5px 15px;
  margin-bottom: 15px;
  border-right: 5px solid #1ab394;

  font-size: 12px;
  white-space: pre-line;
  color: $text-white;
  background-color: $basic-dark-blue;
  animation: fadeInUp 1s;

  &Failure {
    border-right: 5px solid $red;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0,100%,0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
