@import "./src/styles/helpers/_index.scss";
.NavMenu {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 20px;
  background-color: $basic-dark;
  z-index: 10;

  @include breakpoint-tablet-portrait {
    height: auto;
    background: #000;
    justify-content: flex-end;
  }
}

.ButtonIcon {
  display: none;
  position: relative;
  z-index: 10;
  height: 20px;
  width: 28px;
  transition: .5s ease-in-out;
  cursor: pointer;

  @include breakpoint-tablet-portrait {
    display: block;
  }
}

.IconLine {
  position: absolute;
  background: $basic-white;
  width: 100%;
  height: 3px;
  left: 0;
  transition: .25s ease-in-out;

  &:nth-child(1) {
    top: 0;
  }

  &:nth-child(2), &:nth-child(3) {
    top: 8px;
  }

  &:nth-child(4) {
    top: 16px;
  }

  .ButtonIconClose & {
    &:nth-child(1), &:nth-child(4) {
      top: 8px;
      width: 0%;
      left: 50%;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
}

.Links {
  display: flex;
  align-items: center;
  width: 100%;

  @include breakpoint-tablet-portrait {
    display: none;
    bottom: 0;
    transform: translateY(100%);

    padding: 40px;
    position: absolute;
    left: 0;
    flex-direction: column;
    width: 100%;

    box-shadow: 0 -10px 15px 0 hsla(0, 0, 0, 0.1);

    background: $basic-dark;

    &Column {
      transition: .2s ease-in-out;
      display: flex;
    }
  }
}

.Logo {
  margin-right: 10px;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  color: $main-lazur;
}

.Nav {
  &List {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;

    @include breakpoint-tablet-portrait {
      flex-flow: column nowrap;
      justify-content: center;
      text-align: center;
      margin: 50px 0;
    }
  }

  &Item {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    @include breakpoint-tablet-portrait {
      font-size: 16px;
      font-weight: 300;
      padding: 5px;
    }
  }

  &Link {
    display: inline-block;
    width: 100%;
    padding: 5px 10px;
    font-weight: 300;
    font-size: 14px;
    text-decoration: none;
    color: $basic-light-blue;

    &:hover {
      color: $main-lazur;
    }
  }

  &Button {
    margin: 0 5px;
    padding: 10px 30px;
    border: none;
    outline: none;
    border-radius: 3px;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
    background-color: $basic-white;
    color: $basic-dark;

    &:hover {
      background-color: $main-success;
    }
  }
}

.SkLink {
  margin: 0 0 0 16px;
  
  @include breakpoint-tablet-portrait {
    margin: 20px 0 0 0;
  }
} 

.SkLogo {
  height: auto;
  width: 140px;
}
