@import "./src/styles/helpers/_index.scss";
.pdf-context,
.pdf-viewer {
  position: relative;
  width: 100%;
}

.pdf-viewer {
  padding-top: 1em;
  padding-bottom: 3em;
  background-color: rgba(0,0,0,.4); /* Default color #3E3E3E; */
}

.pdf-page {
  position: relative;
  margin: 0 auto 3em auto;
  padding: 0;
  overflow: visible;
  box-shadow: 0px 0px 20px rgba(0,0,0,.8);
  background-color: $basic-white;
  background-clip: content-box;
}