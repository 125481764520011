@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

@mixin clearfix() {
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin breakpoint($width) {
  @media (max-width: $width) {
    @content
  }
}

@mixin breakpoint-min-width($width) {
  @media (min-width: $width) {
    @content
  }
}

@mixin breakpoint-max-height($height) {
  @media (max-height: $height) {
    @content
  }
}

@mixin breakpoint-max-size($width, $height) {
  @media (max-width: $width) and (max-height: $height) {
    @content
  }
}

@mixin breakpoint-min-size($width, $height) {
  @media (min-width: $width) and (min-height: $height) {
    @content
  }
}

@mixin breakpoint-tablet() {
  @media (max-width: 1200px) {
    @content
  }
}

@mixin breakpoint-tablet-portrait() {
  @media (max-width: 960px) {
    @content
  }
}

@mixin breakpoint-mobile() {
  @media (max-width: 640px) {
    @content
  }
}

@mixin breakpoint-mobile-portrait() {
  @media (max-width: 425px) {
    @content
  }
}