@import "./src/styles/helpers/_index.scss";
.Button {
  padding: 10px 15px;
  border: 1px solid transparent;
  border-radius: 15px;
  outline: none;

  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  background-color: $basic-dark-blue;
  color: $text-white;
  cursor: pointer;
  @include font-smoothing(off);

  & + & {
    margin-left: 15px;
  }

  &:hover {
    box-shadow: 0 5px 20px -10px rgba(0,0,0,.57);
    transition: all .4s ease 0s;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      box-shadow: none;
    }
  }

  &Light {
    border: 2px solid $basic-grey;

    background-color: $basic-white;
    color: $basic-dark;
  }

  &Danger {
    background-color: $purple;
    color: $text-white;
  }

  &Primary {
    background-color: $basic-white;
    color: $basic-dark;
  }

  &Transparent {
    background-color: transparent;
    color: $text-grey;
  }

  &TransparentDanger {
    background-color: transparent;
    color: $purple;
  }

  &Success {
    background-color: $main-success;
    color: $text-white;
  }

  &Active {
    border: 1px solid #c6cbd3;

    background-color: transparent;
    color: $basic-dark-blue;
  }

  &Small {
    padding-left: 22px;
    padding-right: 25px;
    margin-left: 0 !important; /* TODO: remove important */
  }

  &Large {
    width: auto;
    padding-left: 24px;
    padding-right: 25px;
  }

  &Big {
    width: 100%;
    padding: 21px 25px;
    border-radius: 30px;
    font-size: 16px;
  }

  &Icon {
    &:before {
      float: left;
      margin: -2px 5px -2px -2px;
      font-size: 20px;
    }
  }

  &WithoutChildren {
    &:before {
      margin-right: -2px;
    }
  }
}
