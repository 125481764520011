$font-directory: './fonts';

/* Clientspace icons */
@font-face {
  font-family: 'clientspace';
  src:  url('#{$font-directory}/clientspace/clientspace.eot?abnhdh');
  src:  url('#{$font-directory}/clientspace/clientspace.eot?abnhdh#iefix') format('embedded-opentype'),
  url('#{$font-directory}/clientspace/clientspace.ttf?abnhdh') format('truetype'),
  url('#{$font-directory}/clientspace/clientspace.woff?abnhdh') format('woff'),
  url('#{$font-directory}/clientspace/clientspace.svg?abnhdh#clientspace') format('svg');
  font-weight: normal;
  font-style: normal;
}