@import "./src/styles/helpers/_index.scss";
.Footer {
  padding: 100px 50px;
  background-color: $basic-dark;
  color: $basic-white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  font-weight: 700;
  font-size: 14px;

  @include breakpoint-mobile {
    flex-direction: column;
    padding: 60px 30px;
  }
}

.FooterLeft {
  display: flex;
  align-items: center;
}

.Title {
  font-size: 22px;
  line-height: 1.55;

  &Lazur {
    color: $main-success;
  }
}

.Contacts {
  font-weight: 400;
  margin: 0 20px;

  @include breakpoint-mobile {
    order: -1;
    margin-bottom: 30px;
  }
}

.Address {
  font-size: 10px;
}

.Link {
  text-decoration: none;
  color: $basic-white;


  span {
    font-size: 24px;
  }
}

.SkLink {
  margin: 0 32px;
}

.SkLogo {
  height: 90px;
  width: auto;
}
